<template>
  <div class="error">抱歉，登录状态已失效，您没有权限访问！</div>
</template>
<style scoped>
.error {
  padding: 120px 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
  text-align: center;
}
</style>
